
import Vue from "vue";
import { User } from "@/store/users/types";

export default Vue.extend({
  name: "EditUser",
  data: () => ({
    visible: false,
    user: {} as User,
    title: "",
    newUser: true,
    validForm: false,
    roles: [
      {
        text: "Viewer",
        value: "VIEWER",
      },
      {
        text: "Editor",
        value: "EDITOR",
      },
      {
        text: "Admin",
        value: "ADMIN",
      },
    ],
  }),
  computed: {
    rules(): Record<string, unknown> {
      return {
        required: (val: string) => !!val || "This field is required",
        email: (val: string) =>
          /.+@cloudwise\.(nl|be)$/.test(val) ||
          "Should be a @cloudwise.nl of @cloudwise.be e-mail address",
      };
    },
  },
  methods: {
    open(user: User): void {
      if (this.$refs.form) {
        (this.$refs.form as HTMLFormElement).resetValidation();
      }
      this.visible = true;
      this.user = new User(user?.email || "", user?.role || "VIEWER");
      this.title = user ? "Edit user" : "New user";
      this.newUser = !user;
    },
    close(event: string): void {
      this.visible = false;
      this.$emit(event, this.user);
    },
  },
});
