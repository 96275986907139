import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"edit-user"},[_c(VDialog,{attrs:{"max-width":"450px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,{staticClass:"pb-2"},[_c(VForm,{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":"E-mail","required":"","readonly":!_vm.newUser},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c(VSelect,{attrs:{"items":_vm.roles,"label":"Role","required":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1)],1),_c(VCardActions,{staticClass:"edit-buttons"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"grey darken-2"},on:{"click":function($event){return _vm.close('cancel')}}},[_c(VIcon,[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancel")])],1),_c(VBtn,{attrs:{"text":"","disabled":!_vm.validForm,"color":"primary"},on:{"click":function($event){return _vm.close('save')}}},[_c(VIcon,[_vm._v("mdi-content-save-outline")]),_c('span',[_vm._v("Save")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }