
import Vue from "vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import EditUser from "@/components/EditUser.vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "Users",
  components: {
    EditUser,
    ConfirmDialog,
  },
  data: () => ({
    search: "",
    headers: [
      { text: "E-mail", value: "email" },
      { text: "Role", value: "role" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
  }),
  created() {
    this.loadUsers();
  },
  computed: {
    ...mapState("users", ["users"]),
    ...mapState("auth", ["authUser"]),
  },
  methods: {
    ...mapActions("users", ["loadUsers", "saveUser", "removeUser"]),
  },
  filters: {
    capitalize(value: string): string {
      return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
    },
  },
});
